/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux'
// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const language = useSelector((state) => state.language.value)
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {/* <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.left}>
              <br></br>
              <h5
                style={{
                  "font-weight": "700",
                }}
              >
                {language
                  ? "Για περισσότερες πληροφορίες σχετικά με το έργο YouthTrams, τα ερευνητικά αποτελέσματα, δυνατότητα συμμετοχής σε έρευνα ή δυνατότητα ερευνητικής συνεργασίας, μπορείτε να επικοινωνήσετε με τη Συντονίστρια Δρ. Σοφία Σταύρου."
                  : "For more information about the YouthTrams project, the research results, the possibility of participating in research or the possibility of research collaboration, you can contact the coordinator Dr. Sophia Stavrou."}
                
              </h5>
            </ListItem>
            <ListItem className={classes.left}>
              Email: sofias@ucy.ac.cy
            </ListItem>
            <ListItem className={classes.left}>
              Facebook:&nbsp;
              <a
                href="https://www.facebook.com/YouthTrams-108938120550963/"
                target="_blank"
                style={{
                  color: 'blue'
                }}
              >
                YouthTrams
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.creative-tim.com/license?ref=mkr-footer"
                className={classes.block}
                target="_blank"
              >
                Licenses
              </a>
            </ListItem>
          </List>
        </div> */}
        <br></br><br></br>
        <div className={classes.right}>
          {/* &copy; {1900 + new Date().getYear()} , made with{" "} */}
           Powered by
           {' '}
           <a href="https://www.thetensortech.com" style={{
             color: 'blue'
           }}>
            Tensor Tech
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
