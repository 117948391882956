import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
// import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
// import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";
// import { FormLabel } from "@material-ui/core";

import { ReactComponent as Logo_tmp } from "../../assets/logo_tmp.svg";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  // const icons = Object.assign({}, {
  //   Logo_tmp
  // })
  return (
    <div>
      <Header
        brand={<Logo_tmp onClick={() => {window.location.href = '/'}}/>}
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white",
        // }}
        {...rest}
      />
      <div
        style={{
          height: 200,
        }}
      ></div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        {/* <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h4 className={classes.title}>Let{"'"}s talk about Youth Trams</h4>
            <h5 className={classes.description}>
              This is the paragraph where you can write more details about your
              lab. Keep you user engaged by providing meaningful information.
              Remember that by this time, the user is curious, otherwise he
              wouldn
              {"'"}t scroll to get here. Add a button if you want the user to
              see more.
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <h5 className={classes.description}>
              This is the paragraph where you can write more details about your
              lab. Keep you user engaged by providing meaningful information.
              Remember that by this time, the user is curious, otherwise he
              wouldn
              {"'"}t scroll to get here. Add a button if you want the user to
              see more.
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <h5 className={classes.description}>
              This is the paragraph where you can write more details about your
              lab. Keep you user engaged by providing meaningful information.
              Remember that by this time, the user is curious, otherwise he
              wouldn
              {"'"}t scroll to get here. Add a button if you want the user to
              see more.
            </h5>
          </GridItem>
        </GridContainer> */}
        <SectionBasics />
        {/* <SectionNavbars />
        <SectionTabs />
        <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples />
        <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload /> */}
      </div>
      <Footer />
    </div>
  );
}
