import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useSelector } from 'react-redux'



import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const language = useSelector((state) => state.language.value)
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>
          {language ? "Επικοινωνήστε μαζί μας" : "Contact us"}
            </h2>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  style={{width: "100%"}}
                  variant="outlined"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  style={{width: "100%"}}
                  variant="outlined"
                />
              </GridItem>
              <br></br><br></br><br></br>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  multiline
                  margin="dense"
                  id="message"
                  label="Message"
                  type="text"
                  style={{width: "100%"}}
                  variant="outlined"
                  rows={10}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              <Button
                round
                style={{
                  "font-family": "Courier New",
                  border: "1px solid",
                  backgroundColor: "white",
                  "border-color": "blue",
                  color: "blue",
                }}
              >
                {language ? "Αποστολή" : "Send"}
                
              </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
