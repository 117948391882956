import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux'

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/Sofia.jpg";
import team2 from "assets/img/faces/Panayiotis.jpg";
import team3 from "assets/img/faces/Mirto.jpg";
import team4 from "assets/img/faces/Michael.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const language = useSelector((state) => state.language.value)
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>{language ? "ΟΜΑΔΑ" : "TEAM"}</h2>
      <div id="first_paragraph" style={{ "padding-top": "50px" }}>
        <h3 className={classes.title}>
          {language ? "ΣΥΝΤΟΝΙΣΤΡΙΑ ΕΡΓΟΥ" : "PROJECT COORDINATOR"}
        </h3>
        <GridContainer>
          <GridItem xs={12} sm={4} md={2} style={{ "padding-top": "50px" }}>
            <img
              src={team1}
              alt="..."
              className={
                classes.imgRaised +
                " " +
                classes.imgRoundedCircle +
                " " +
                classes.imgFluid +
                " " +
                classes.title
              }
            />
          </GridItem>
          <GridItem xs={12} sm={8} md={10}>
            <div className={classes.title}>
              <h3>{language ? "Σοφία Σταύρου" : "Sophia Stavrou"}</h3>
            </div>
            <p className={classes.description}>
              {language
                ? "Η Δρ. Σοφία Σταύρου είναι Λέκτορας Κοινωνιολογίας και εργάζεται στο Τμήμα Κοινωνικών και Πολιτικών Επιστημών του Πανεπιστημίου Κύπρου. Είναι κάτοχος διδακτορικού τίτλου στην Κοινωνιολογία από το Πανεπιστήμιο Aix-Marseille στη Γαλλία. Τα κύρια ερευνητικά της ενδιαφέροντα εστιάζονται στην κοινωνιολογία της ανώτατης εκπαίδευσης, την πολιτική για την ανώτατη εκπαίδευση και τις μεταλλάξεις της σχέσης των νέων με την εκπαίδευση και την εργασία στο πλαίσιο της κοινωνίας της γνώσης και των σύγχρονων κοινωνικών και πολιτισμικών μετασχηματισμών. Έχει δημοσιεύσει επιστημονικά άρθρα σε αυτά το πεδία στην αγγλική, γαλλική και ελληνική γλώσσα και τη μονογραφία L’Université au Diapason du Marché [The University in Tune with the Market], Academia-L’Harmattan (2017). Ως Συντονίστρια του προγράμματος YouthTrams (2019-2021) ανέλαβε τον σχεδιασμό και συμμετείχε ως κύρια ερευνήτρια σε όλες τις φάσεις του έργου."
                : "Dr. Sophia Stavrou is a Lecturer in Sociology at the Department of Social and Political Sciences of the University of Cyprus. She holds a PhD in Sociology from the University of Aix-Marseille in France. Her main research interests focus on the sociology of higher education, higher education policy and the transformations of young people's relationship with education and work in the context of the knowledge society and of contemporary social and cultural transformations. She has published scholarly articles in these fields in English, French and Greek and the monograph L'Université au Diapason du Marché [The University in Tune with the Market], Academia-L'Harmattan (2017). As Coordinator of the YouthTrams project (2019-2021), she was in charge of the research design and participated as principal investigator in all phases of the project."}
            </p>
            <Button justIcon color="transparent" className={classes.margin5}>
              <i className={classes.socials + " fab fa-linkedin"} />
            </Button>
          </GridItem>
        </GridContainer>
      </div>
      <div id="sec_paragraph" style={{ "padding-top": "50px" }}>
        <h3 className={classes.title}>
          {language
            ? "ΕΡΕΥΝΗΤΙΚΟΙ ΣΥΝΕΡΓΑΤΕΣ/ΣΥΝΕΡΓΑΤΙΔΕΣ"
            : "RESEARCH COLLABORATORS"}
        </h3>
        <br></br>
        <br></br>
        <GridContainer>
          <GridItem xs={12} sm={4} md={2} style={{ "padding-top": "50px" }}>
            <img
              src={team2}
              alt="..."
              className={
                classes.imgRaised +
                " " +
                classes.imgRoundedCircle +
                " " +
                classes.imgFluid +
                " " +
                classes.title
              }
            />
          </GridItem>
          <GridItem xs={12} sm={8} md={10}>
            <div className={classes.title}>
              <h3>
                {language ? "Παναγιώτης Αχνιώτης" : "Panagiotis Achniotis"}
              </h3>
            </div>
            <p className={classes.description}>
              {language
                ? "Ο Παναγιώτης Αχνιώτης είναι υποψήφιος διδάκτορας στο Πανεπιστήμιο του Μάντσεστερ στο Τμήμα Κοινωνικής Ανθρωπολογίας. Η έρευνα του ασχολείται με τα κοινωνικά κινήματα της Βαρκελώνης σε σχέση με το αστικό πεδίο και την σύγκρουση κυριαρχίας στην Καταλονία. Το ερευνητικό του ενδιαφέρον εστιάζεται στο πώς οι άνθρωποι νοηματοδοτούν το περιβάλλον τους και επιδρούν ως προς την κοινωνική αλλαγή. Στο πρόγραμμα Youthtrams συνέβαλε κυρίως στη διεξαγωγή της ποιοτικής έρευνας με τους νέους και νέες. Συμμετείχε στον σχεδιασμό της έρευνας, τη διενέργεια ημι-δομημένων συνεντεύξεων και την ανάλυση των ποιοτικών δεδομένων."
                : "Panagiotis Achniotis is a PhD candidate at the University of Manchester in the Department of Social Anthropology. His research deals with social movements in Barcelona in relation to the urban field and the conflict of sovereignty in Catalonia. His research interest focuses on how people make sense of their environment and influence it in terms of social change. In the Youthtrams project he mainly contributed to conducting qualitative research with young people. He was involved in designing the research, conducting semi-structured interviews and analysing the qualitative data."}
            </p>
            <Button justIcon color="transparent" className={classes.margin5}>
              <i className={classes.socials + " fab fa-linkedin"} />
            </Button>
          </GridItem>
        </GridContainer>
      </div>
      <div id="third_paragraph">
        <GridContainer>
          <GridItem xs={12} sm={4} md={2} style={{ "padding-top": "50px" }}>
            <img
              src={team3}
              alt="..."
              className={
                classes.imgRaised +
                " " +
                classes.imgRoundedCircle +
                " " +
                classes.imgFluid +
                " " +
                classes.title
              }
            />
          </GridItem>
          <GridItem xs={12} sm={8} md={10}>
            <div className={classes.title}>
              <h3>{language ? "Μυρτώ Αναστασιάδου" : "Myrto Anastasiadou"}</h3>
            </div>
            <p className={classes.description}>
              {language
                ? "Η Μυρτώ Αναστασιάδου έχει ολοκληρώσει τις προπτυχιακές της σπουδές στο Πανεπιστήμιο Rutgers και είναι κάτοχος πτυχίου Κοινωνιολογίας και Διαχείρισης Ανθρώπινου Δυναμικού, με ερευνητικό ενδιαφέρον στο πεδίο της εργασίας. Κατέχει μεταπτυχιακό MSc στην Κοινωνιολογία (Έρευνα) από το London School of Economics με εξειδίκευση στις ποσοτικές και ποιοτικές μεθόδους έρευνας. Είναι υποψήφια διδάκτορας στο Πανεπιστήμιο Κύπρου στο Τμήμα Διοίκησης Επιχειρήσεων και Δημόσιας Διοίκησης. Στο πρόγραμμα YouthTrams (2019-2021) συμμετείχε στην αρχειακή έρευνα στο πεδίο της κοινωνικής πολιτικής για τη νεανική απασχόληση και ήταν σε στενή επαφή με εθνικούς φορείς για τη συλλογή δευτερογενών στατιστικών δεδομένων. Συμμετείχε στη διεξαγωγή της πιλοτικής έρευνας μέσω συνεντεύξεων."
                : "Myrto Anastasiadou has completed her undergraduate studies at Rutgers University and holds a degree in Sociology and Human Resource Management, with a research interest in the area of work. She holds an MSc in Sociology (Research) from the London School of Economics, specialising in quantitative and qualitative research methods. She is a PhD candidate at the University of Cyprus in the Department of Business and Public Administration. In the YouthTrams project (2019-2021) she participated in archival research in the field of social policy on youth employment and was in close contact with national institutions to collect secondary statistical data. She participated in conducting the pilot survey through interviews. "}
            </p>
            <Button justIcon color="transparent" className={classes.margin5}>
              <i className={classes.socials + " fab fa-linkedin"} />
            </Button>
          </GridItem>
        </GridContainer>
      </div>
      <div id="forth_paragraph" style={{ "padding-top": "50px" }}>
        <GridContainer>
          <GridItem xs={12} sm={4} md={2} style={{ "padding-top": "50px" }}>
            <img
              src={team4}
              alt="..."
              className={
                classes.imgRaised +
                " " +
                classes.imgRoundedCircle +
                " " +
                classes.imgFluid +
                " " +
                classes.title
              }
            />
          </GridItem>
          <GridItem xs={12} sm={8} md={10}>
            <div className={classes.title}>
              <h3>{language ? "Μιχάλης Τζιωνής" : "Michalis Tzionis"}</h3>
            </div>
            <p className={classes.description}>
              {language
                ? "Ο Μιχάλης Τζιωνής ολοκλήρωσε τις προπτυχιακές σπουδές του στην Κοινωνιολογία το 2019 στο Πανεπιστήμιο Κύπρου. Το 2020 αποφοίτησε με MSc Economy, Risk and Society από το London School of Economics και εξειδικεύτηκε τόσο στις ποσοτικές, όσο και στις ποιοτικές ερευνητικές μεθόδους. Το ερευνητικό του ενδιαφέρον εστιάζεται στο πώς διαμορφώνονται οι κοινωνικές αντιλήψεις για την αξία, ιδιαίτερα τις μη-«εκλογικευμένες» πτυχές που επηρεάζουν «εκλογικευμένες» αποφάσεις στον κοινωνικό-οικονομικό τομέα. Στο πρόγραμμα YouthTrams συνέβαλε κυρίως στην ποσοτική έρευνα για τη σχέση των νέων από την ανώτατη εκπαίδευση στην εργασία, τον σχεδιασμό ερωτηματολογίου και την ανάλυση των ποσοτικών δεδομένων μέσω διαφόρων στατιστικών γλωσσών."
                : "Michalis Tzionis completed his undergraduate studies in Sociology in 2019 at the University of Cyprus. In 2020 he graduated with an MSc Economy, Risk and Society from the London School of Economics and specialised in both quantitative and qualitative research methods. His research interest focuses on how social perceptions of value are shaped, particularly the non-'rationalised' aspects that influence 'rationalised' decisions in the socio-economic domain. In the YouthTrams project he mainly contributed to the quantitative research on young people’s trajectories from higher education to work. He participated to the design of the questionnaire survey, as well as the analysis of quantitative data through different statistical languages."}
            </p>
            <Button justIcon color="transparent" className={classes.margin5}>
              <i className={classes.socials + " fab fa-linkedin"} />
            </Button>
            {/* <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-instagram"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-facebook"} />
                </Button> */}
            {/* <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button> */}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
