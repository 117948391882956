import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useSelector } from "react-redux";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

import Pdf from "assets/youthtrams_part_I.pdf";
import roadMap from "assets/roadMap.png";

const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const language = useSelector((state) => state.language.value);
  const classes = useStyles();

  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.major_title}>
              {language
                ? "Ερευνητικό Πρόγραμμα YouthTrams"
                : "Research Project YouthTrams"}
            </h2>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <h3>
              <b>
                {language
                  ? "Επιστημονικά άρθρα από το έργο YouthTrams"
                  : "YouthTrams project's scientific articles"}
              </b>
            </h3>
            <p style={{ fontSize: "15px" }}>
              Sophia Stavrou & Panos Achniotis (2021){" "}
              <b>
                Towards a revaluation of work in post-Fordist societies?
                Critical reconversions of highly skilled youth and negotiations
                of fluid relationships with work
              </b>
              , Journal of Youth Studies, DOI:
              <b>
                <a
                  href="https://www.tandfonline.com/doi/full/10.1080/13676261.2021.2010685"
                  target="_blank"
                  style={{
                    color: "blue",
                  }}
                >
                  10.1080/13676261.2021.2010685
                </a>
              </b>
            </p>
            <br></br>
            <p style={{ fontSize: "15px" }}>
              Sophia Stavrou (2022){" "}
              <b>
                Structuring forms of transition from higher education to
                employment: bridging Bernstein and Bourdieu in understanding
                mismatch
              </b>
              , Journal of Education and Work, DOI:
              <b>
                <a
                  href="https://www.tandfonline.com/doi/full/10.1080/13639080.2022.2036709"
                  target="_blank"
                  style={{
                    color: "blue",
                  }}
                >
                  10.1080/13639080.2022.2036709
                </a>
              </b>
            </p>
            <br></br>
            <p style={{ fontSize: "15px" }}>
              Dimitris Trimithiotis & Sophia Stavrou (2022){" "}
              <b>
                Digitalisation as Discursive Construction: Entrepreneurial
                Labour and the Fading of Horizons of Expectations for Newcomer
                Journalists
              </b>
              , Journalism Studies, DOI:
              <b>
                <a
                  href="https://www.tandfonline.com/doi/full/10.1080/1461670X.2022.2143866"
                  target="_blank"
                  style={{
                    color: "blue",
                  }}
                >
                  10.1080/1461670X.2022.2143866
                </a>
              </b>
            </p>
            <br></br>
            <h3>
              <b>
                {language
                  ? "Ερευνητικές εκθέσεις από το έργο YouthTrams"
                  : "YouthTrams project's research reports"}
              </b>
            </h3>
            <p style={{ fontSize: "15px" }}>
              Σοφία Σταύρου και Μιχάλης Τζιωνής,{" "}
              <b>
                <a
                  href={Pdf}
                  target="_blank"
                  style={{
                    color: "blue",
                  }}
                >
                  Η σχέση των νέων πτυχιούχων πανεπιστημίου με την εργασία στην
                  Κύπρο. Μέρος Α
                </a>
              </b>
              , Ερευνητική Έκθεση “YouthTrams”: Sociology of Youth Trajectories
              in the Making, Πανεπιστήμιο Κύπρου, Ιούνιος 2021.<br></br>
              {/* <b>
                <a
                  href={Pdf}
                  target="_blank"
                  style={{
                    color: "blue",
                  }}
                >
                  Pdf
                </a>
              </b> */}
            </p>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={roadMap}
              alt="..."
              style={{ margin: "0px", padding: "0px" }}
              className={classes.imgFluid + " " + classes.title}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <br></br>
            <h3>
              <b>
                {language
                  ? "Παρουσιάσεις/εισηγήσεις σε συνέδρια από το έργο YouthTrams"
                  : "YouthTrams project's presentations/suggestions to conferences"}
              </b>
            </h3>
            <p style={{ fontSize: "15px" }}>
              Stavrou S.,{" "}
              <b>
                Understanding old and new inequalities in graduate employment:
                bridging Bernstein and Bourdieu
              </b>
              . International Conference on Research into Higher Education 2021
              (Re)connecting (Re)building HIgher Education in Transformative
              Times, Society for Research into Higher Education, 6-10 December
              2021.
              <br></br>
              <br></br>
            </p>
            <p style={{ fontSize: "15px" }}>
              Stavrou S. and P. Achniotis,{" "}
              <b>
                Critical Reconversions of Highly Skilled Youth Under
                Socio-Cultural and Economic Change
              </b>
              , RN30 Education-Work Transition, International Conference of the
              European Sociological Association, Barcelona,1-3 August 2021.
              <br></br>
              <br></br>
            </p>
            <p style={{ fontSize: "15px" }}>
              Stavrou S. and D. Trimithiotis,{" "}
              <b>
                L’organisation du travail journalistique face aux contraintes du
                numérique : entre classements professionnels et déclassements
                économiques
              </b>
              , 8e Congrès de l'AFS Classer/Dé-classer/Re-classer,
              Aix-en-Provence, Août 2019, France.
              <br></br>
              <br></br>
            </p>
            <p style={{ fontSize: "15px" }}>
              Stavrou S. and D. Trimithiotis,{" "}
              <b>
                How does digitization lead to precarization in the field of
                journalism? The case of Cyprus
              </b>
              , IAMCR 2019 Communication, Technology and Human Dignity: Disputed
              Rights, Contested Truths, Madrid, July 2019, Spain.
              <br></br>
              <br></br>
            </p>
            <p style={{ fontSize: "15px" }}>
              Stavrou S. and D. Trimithiotis,{" "}
              <b>
                Grasping the relation between higher education and work in the
                field of journalism and media. Α comprehensive integrative
                sociological approach
              </b>
              , Work, Employment and Society Conference 'Putting Sociology to
              Work: Interdisciplinarity, intersectionality and imagination',
              Belfast, Ireland, September.
              <br></br>
              <br></br>
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
