import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/components.js";

import { ReactComponent as Logo_tmp } from "../../assets/logo_tmp.svg";

// Sections for this page
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection";

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="white"
        // routes={dashboardRoutes}
        brand={<Logo_tmp onClick={() => {window.location.href = '/'}}/>}
        rightLinks={<HeaderLinks />}
        fixed
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white",
        // }}
        {...rest}
      />
      <div
        style={{
          height: 200,
        }}
      ></div>
      <div className={classNames(classes.main, classes.mainRaised)}>
          {/* <TeamSection /> */}
          <WorkSection />
      </div>
      <Footer />
    </div>
  );
}
