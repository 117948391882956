import React from "react";
// plugin that creates slider
// import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import Radio from "@material-ui/core/Radio";
// import Switch from "@material-ui/core/Switch";
// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";
// import People from "@material-ui/icons/People";
// import Check from "@material-ui/icons/Check";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
// import Paginations from "components/Pagination/Pagination.js";
// import Badge from "components/Badge/Badge.js";

// import image from "assets/img/faces/avatar.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import { useSelector } from 'react-redux'

import manThink from "assets/manThink.png";

const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const language = useSelector((state) => state.language.value)
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.major_title}>
              {language ? "Λάβε μέρος στην Έρευνα" : "Participate in Research"}
            </h2>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <p>
              {language
                ? "Στο πλαίσιο της έρευνας «From Higher Education to Work: Youth Trajectories in the Making» (YOUTHTRAMS) γίνεται συλλογή στοιχείων για τη μετάβαση των νέων από το πανεπιστήμιο στην εργασία στην Κύπρο."
                : 'The objective of the Research Project "From Higher Education to Work: Youth Trajectories in the Making" (YouthTrams), is to collect data on the transition of young people from university to work in Cyprus.'}
              <br></br>
              <br></br>
              {language
                ? "Η συμμετοχή σας στην έρευνα είναι πολύ σημαντική για την καταγραφή της εργασιακής κατάστασης των νέων στην Κύπρο."
                : "Your participation in the survey is very important to record the work situation of young people in Cyprus."}

              <br></br>
              <br></br>
              {language
                ? "Γίνετε εθελοντές/εθελόντριες στην έρευνα μας αν:"
                : "Become a volunteer in our survey if:"}
              <br></br>
              {language
                ? "• Είστε πτυχιούχοι πανεπιστημίου, της Κύπρου ή του εξωτερικού"
                : "• You are a graduate of a university, in Cyprus or abroad"}

              <br></br>
              {language
                ? "• Έχετε πάρει το πτυχίο σας (bachelor) από το 2007 και μετά"
                : "• You have received your bachelor's degree from 2007 onwards."}
              <br></br>
              {language
                ? "• Zείτε στην Κύπρο"
                : "• You have a bachelor's degree or master's degree from a university or university of applied sciences"}

              <br></br>
              <br></br>
              {language
                ? "Χρειάζονται περίπου 10 λεπτά για τη συμπλήρωση του πιο κάτω ερωτηματολογίου. Το ερωτηματολόγιο περιλαμβάνει 4 μέρη:"
                : "It takes about 10 minutes to complete the questionnaire below. The questionnaire consists of 4 parts:"}

              <br></br>
              {language ? "Α. Εκπαίδευση" : "A. Education"}
              <br></br>
              {language ? "Β. Εργασιακή πορεία" : "B. Employment trajectory"}
              <br></br>
              {language ? "Γ. Σημερινή Εργασία" : "C. Current work situation"}
              <br></br>
              {language ? "Δ. Δημογραφικά στοιχεία" : "D. Demographics"}
              <br></br>
              <br></br>
              {language
                ? "Οι πληροφορίες συλλέγονται ανώνυμα και η επεξεργασία τους δεν επιτρέπει σε καμία περίπτωση την ταυτοποίηση των ερωτώμενων. Τα δεδομένα θα χρησιμοποιηθούν μόνο για σκοπούς επιστημονικής έρευνας."
                : "The information is collected anonymously and its processing does not allow in any way to identify the respondents. The data will be used only for scientific research purposes."}
            </p>
            <br></br>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img
              src={manThink}
              alt="..."
              style={{ margin: "0px", padding: "0px" }}
              className={classes.imgFluid + " " + classes.title}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <b>
              <p>
                {language ? "Ερωτηματολόγιο " : "Questionnaire "}

                <a
                  href="https://docs.google.com/forms/d/1L1FsZXtIboyQFmxGM1Ru0NIeRI_KicDzd2zE0kC1g8g/edit?fbclid=IwAR2WvSIwUo3S-pkuLWaRALpE-0TzXsXMJ3dabSqXyXLHJTe2jfSe6VK2SNU"
                  style={{
                    color: "blue",
                  }}
                >
                  {language ? "YouthTrams" : "YouthTrams"}
                </a>
              </p>
            </b>
            <br></br>
            <b>
              <p>
                {language
                  ? "Σας ευχαριστούμε θερμά για τον χρόνο που θα αφιερώσετε σε αυτή την έρευνα!"
                  : "Thank you very much for taking the time to complete this survey!"}

                <br></br>
                <br></br>
                {language ? "Δρ. Σοφία Σταύρου" : "Dr. Sophia Stavrou"}

                <br></br>
                {language
                  ? "Τμήμα Κοινωνικών και Πολιτικών Επιστημών"
                  : "Department of Social and Political Sciences"}

                <br></br>
                {language ? "Πανεπιστήμιο Κύπρου" : "University of Cyprus"}

                <br></br>
                <a
                  href="mailto:youthtrams.ucy@gmail.com"
                  style={{
                    color: "blue",
                  }}
                >
                  youthtrams.ucy@gmail.com
                </a>
              </p>
            </b>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
