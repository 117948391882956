import React, { useState, useEffect } from "react";
// nodejs library that concatenates    classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import {Card, CardMedia } from "@material-ui/core";
// import image from "assets/img/simple_graph.png";

// import All_Data_function from "views/ResearchPage/All_data.js";
import { CChart } from "@coreui/react-chartjs";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import styles from "assets/jss/material-kit-react/views/components.js";

import researchPageIcon from "assets/researchPage.png";


import { ReactComponent as Logo_tmp } from "../../assets/logo_tmp.svg";
import {
  CFormGroup,
  CLabel,
  // CInput,
  // CModal,
  // CModalBody,
  // CModalFooter,
  // CModalHeader,
  // CModalTitle,
} from "@coreui/react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "chartjs-plugin-datalabels";
import { Alarm } from "@material-ui/icons";

let options = {
  tooltips: {
    enabled: false,
    // custom: customTooltips
  },
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      align: "end",
      anchor: "end",
      font: { size: "12", family: "Courier New" },
    },
    // legend: {
    //   labels: {
    //     font: {
    //       size: 30,
    //       family: "Courier New",
    //     },
    //   },
    // },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
          fontFamily: "Courier New",
          fontSize: "12",
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontFamily: "Courier New",
          fontSize: "12",
        },
      },
    ],
  },
  maintainAspectRatio: false,
  legend: {
    position: "bottom",
    labels: {
      fontFamily: "Courier New",
      fontSize: 12,
    },
  },
};


const all_data = [
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Ωράριο",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Άλλος λόγος",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Άλλο",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακό περιβάλλον",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Εκπαίδευσης",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Άλλο",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Κακές εργασιακές συνθήκες",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Αυτο-ανάπτυξη",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συνάφεια με τις σπουδές μου",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "2 (Μεσαία)",
    "Ωράριο",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Ωράριο",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "40+",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Άνδρας",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Δεν ξέρω/Δεν απαντώ",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Άλλος λόγος",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Κακές εργασιακές συνθήκες",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "PhD",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "5. Ένοπλες δυνάμεις",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Άλλος λόγος",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Όχι",
    "PhD",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Άλλος λόγος",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Άλλος λόγος",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Άλλος λόγος",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Άλλος λόγος",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Άλλος λόγος",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακό περιβάλλον",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "3. Τριτοβάθμια",
    "1. Πρωτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "1. Πρωτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Είμαι άνεργος/η",
    "Οικογενειακές υποχρεώσεις",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Είμαι άνεργος/η",
    "Οικογενειακές υποχρεώσεις",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "1. Πρωτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι άνεργος/η",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Είμαι άνεργος/η",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "1 (Μικρή)",
    "Κακές εργασιακές συνθήκες",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Προοπτικές υψηλών απολαβών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συνάφεια με τις σπουδές μου",
    "",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Αυτο-ανάπτυξη",
    "1 (Μικρή)",
    "Ωράριο",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Ωράριο",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Πάνω από 1700 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ωράριο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "3. Τριτοβάθμια",
    "1. Πρωτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συνάφεια με τις σπουδές μου",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Ωράριο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Κακές εργασιακές συνθήκες",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "2 (Μεσαία)",
    "Άλλος λόγος",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "40+",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "2 (Μεσαία)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ωράριο",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Άλλος λόγος",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Έλλειψη/Πίεση χρόνου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Μισθός",
    "",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "40+",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακό περιβάλλον",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Αυτο-ανάπτυξη",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "1. Πρωτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ωράριο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "5. Ένοπλες δυνάμεις",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Μισθός",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Άλλος λόγος",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Άλλος λόγος",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Οικογενειακές υποχρεώσεις",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Θετικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Ωράριο",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Τεχνών",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "Πρακτική άσκηση με απολαβές",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Ωράριο",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Ωράριο",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "5. Ένοπλες δυνάμεις",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ωράριο",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακό περιβάλλον",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακό περιβάλλον",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "Πρακτική άσκηση με απολαβές",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Πάνω από 1700 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "1. Πρωτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Άλλος λόγος",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Άνδρας",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Ωράριο",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακό περιβάλλον",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "5. Ένοπλες δυνάμεις",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άλλο",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "1. Πρωτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακό περιβάλλον",
    "",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Ωράριο",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Άλλος λόγος",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Άλλος λόγος",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Κακές εργασιακές συνθήκες",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Ωράριο",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Ωράριο",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "5. Ένοπλες δυνάμεις",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "2 (Μεσαία)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη/Πίεση χρόνου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Είμαι άνεργος/η",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι άνεργος/η",
    "Οικογενειακές υποχρεώσεις",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Είμαι άνεργος/η",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "3. Τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Είμαι άνεργος/η",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι άνεργος/η",
    "Οικογενειακές υποχρεώσεις",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Άλλος λόγος",
    "",
    "",
    "Είμαι άνεργος/η",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Οικογενειακές υποχρεώσεις",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Έλλειψη/Πίεση χρόνου",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Είμαι άνεργος/η",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Κακές εργασιακές συνθήκες",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "",
    "2 (Μεσαία)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συνάφεια με τις σπουδές μου",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Ωράριο",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Επισφαλής εργασία",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "2 (Μεσαία)",
    "Ωράριο",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Είμαι άνεργος/η",
    "Οικογενειακές υποχρεώσεις",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Σπουδές στον κλάδο",
    "",
    "Ωράριο",
    "",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άλλο",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακό περιβάλλον",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "`",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Ωράριο",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Μισθός",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Θετικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Ωράριο",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Αυτο-ανάπτυξη",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Άλλος λόγος",
    "",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Άλλος λόγος",
    "Άλλος λόγος",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "40+",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη/Πίεση χρόνου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Είμαι άνεργος/η",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Αυτο-ανάπτυξη",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Έλλειψη/Πίεση χρόνου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακό περιβάλλον",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "Αυτοεργαζόμενος/η",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ωράριο",
    "",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Άλλος λόγος",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Δεν ξέρω/Δεν απαντώ",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Θετικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Οικογενειακές υποχρεώσεις",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "5. Ένοπλες Δυνάμεις",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "2 (Μεσαία)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συνάφεια με τις σπουδές μου",
    "",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "3. Τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συνάφεια με τις σπουδές μου",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Ωράριο",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για εργασία",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Επισφαλής εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Αυτοεργαζόμενος/η",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Συνάφεια με τις σπουδές μου",
    "Αυτο-ανάπτυξη",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακό περιβάλλον",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Ωράριο",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακό περιβάλλον",
    "Ωράριο",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "3. Τριτοβάθμια",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Σπουδές στον κλάδο",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Αυτο-ανάπτυξη",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ωράριο",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Εργασιακή ασφάλεια",
    "Ωράριο",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "3. Τριτοβάθμια",
    "",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Άλλος λόγος",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "1 (Μικρή)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ωράριο",
    "Μισθός",
    "2 (Μεσαία)",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "1 (Μικρή)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "2 (Μεσαία)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Ωράριο",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Έλλειψη/Πίεση χρόνου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Αυτο-ανάπτυξη",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Κακές εργασιακές συνθήκες",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Εργασιακή ασφάλεια",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "3 (Μεγάλη)",
    "Ωράριο",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Μη-κυβερνητική οργάνωση",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "",
    "",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "2 (Μεσαία)",
    "Απλή επιθυμία αλλαγής",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "",
    "",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "1. Πρωτοβάθμια",
    "1. Πρωτοβάθμια",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "Ωράριο",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "",
    "",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη/Πίεση χρόνου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "6. Ανιδείκευτοι εργάτες",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Δεν ξέρω/Δεν απαντώ",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Τεχνών",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Απλή επιθυμία αλλαγής",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν έχω επαρκή προσόντα για πρόσληψη εντός του κλάδου μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Μισθός",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Ναι, εργάζομαι σε περισσότερες από 2 θέσεις εργασίας",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "Όχι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Απλή επιθυμία αλλαγής",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες απολαβές",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Εργασιακό περιβάλλον",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω χαμηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Προτίμησα θέση με καλύτερες εργασιακές συνθήκες",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "3 (Μεγάλη)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1251-1699 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "",
    "2 (Μεσαία)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Άλλος λόγος",
    "Άλλος λόγος",
    "Συνάφεια με τις σπουδές μου",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Κακές εργασιακές συνθήκες",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Αυτοεργαζόμενος/η",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Άλλος λόγος",
    "",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Μισθός",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Δεν με ικανοποιεί η φύση της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Ανεπάρκεια ζήτησης στην αγορά εργασίας ή αποτυχία πρόσληψης",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συμβολή στην κοινωνία, πολιτισμική αξία",
    "Συνάφεια με τις σπουδές μου",
    "3 (Μεγάλη)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "Η θέση εργασίας μου δεν έχει συνάφεια με τις σπουδές μου",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "1. Ανώτερα διευθυντικά στελέχη",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές ασφάλειας στη μελλοντική εργασία",
    "Πρίν το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Εργασιακή ασφάλεια",
    "",
    "1 (Μικρή)",
    "Ωράριο",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Συνάφεια με τις σπουδές μου",
    "Αυτο-ανάπτυξη",
    "1 (Μικρή)",
    "Ωράριο",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πρακτική άσκηση με απολαβές",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Ωράριο",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Ωράριο",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άλλο",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω υψηλότερα εκπαιδευτικά προσόντα (τίτλους εκπαίδευσης/κατάρτισης)",
    "Mismatched",
    "Δεν με ικανοποιούσε ο κλάδος μου",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Ωράριο",
    "Μισθός",
    "1 (Μικρή)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από σύντροφο/σύζυγο",
    "",
    "2. Δευτεροβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Άλλος λόγος",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Ωράριο",
    "Κακές εργασιακές συνθήκες",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Μεταξύ 1001-1250 ευρω",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Εργασιακή ασφάλεια",
    "",
    "3 (Μεγάλη)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Ελευθερία και δυνατότητα δημιουργικότητας",
    "Εργασιακή ασφάλεια",
    "0 (καθόλου)",
    "",
    "",
    "Ναι, εργάζομαι σε δεύτερη εργασία",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Εργασιακή ασφάλεια",
    "Εργασιακό περιβάλλον",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "",
    "Οχι",
    "Οχι, σε καμιά άλλη περίπτωση",
    "",
    "",
    "Άνδρας",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Προοπτικές υψηλών απολαβών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για εργασία",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση ορισμένου χρόνου",
    "",
    "Δεν γνωρίζω/Δεν απαντώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "3 (Μεγάλη)",
    "Απλή επιθυμία αλλαγής",
    "Ωράριο",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "",
    "",
    "",
    "",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "1 (Μικρή)",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Απλή επιθυμία αλλαγής",
    "Οχι",
    "Σε εκπαίδευση/κατάρτιση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "",
    "",
    "",
    "",
  ],
  [
    "Εκπαίδευσης",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Ναι",
    "",
    "",
    "Δημόσιος",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "",
    "Συνάφεια με τις σπουδές μου",
    "",
    "2 (Μεσαία)",
    "Θέλω να επιστρέψω στην εκπαίδευση",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Πλήρη απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Πάνω από 1700 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Εργασιακή ασφάλεια",
    "Μισθός",
    "0 (καθόλου)",
    "",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Κακές εργασιακές συνθήκες",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Ναι",
    "",
    "",
    "Ιδιωτικός",
    "Μερική απασχόληση με σύμβαση αορίστου χρόνου",
    "",
    "Κάτω από 1000 ευρώ",
    "Θεωρώ ότι έχω το κατάλληλο επίπεδο εκπαιδευτικών προσόντων που απαιτεί η θέση",
    "Matched",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "Αυτο-ανάπτυξη",
    "Εργασιακή ασφάλεια",
    "2 (Μεσαία)",
    "Δεν με ικανοποιεί η οικονομική πτυχή της εργασίας μου",
    "",
    "Οχι",
    "Ενασχόληση με κάποια άλλη, μη-αμειβόμενη εργασιακή κατάσταση",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "δεν ήσασταν ούτε σε εκπαίδευση ούτε σε αναζήτηση εργασίας",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "36-40",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "6. Ανιδείκευτοι εργάτες",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Προοπτικές γρήγορης/εύκολης εργοδότησης",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Ανθρωπιστικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Bachelor",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "1. Πρωτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Ναι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Μία ή δύο φορές",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Είμαι άνεργος/η",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Οχι",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "1. Ανώτερα διευθυντικά στελέχη",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ακαδημαϊκή επίδοση (βαθμός πτυχίων)",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Πρίν το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Έλλειψη αξιών ή αυτονομίας στην εργασία",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Είμαι άνεργος/η",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "6. Ανιδείκευτοι εργάτες",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποιο πρόγραμμα πρακτικής άσκησης στην Κύπρο ή στο εξωτερικό",
    "",
    "Ναι",
    "Master",
    "Ναι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Θετικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Όχι",
    "PhD",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "Ναι, σε πρόγραμμα διεθνούς/ευρωπαϊκής κινητικότητας (π.χ. Erasmus)",
    "Ναι, για σπουδές",
    "Ναι, για εργασία",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Πάνω από 3 φορές",
    "Κακές εργασιακές συνθήκες",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, άλλο (π.χ. εισόδημα από ενοίκιο)",
    "2. Δευτεροβάθμια",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Ελληνικό πανεπιστήμιο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "Όχι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Μεταξύ 3 και 6 μηνών",
    "Μια ή δύο φορές",
    "Λιγότερο από 3 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Δεν με ικανοποιούσε η φύση της εργασίας",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "",
    "",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Τεχνών",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Πρίν το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Μια ή δύο φορές",
    "Πάνω από 7 μήνες",
    "Μία ή δύο φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "",
    "",
    "Είμαι άνεργος/η",
    "Μη ικανοποιητικοί όροι εργασίας",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "31-35",
    "Έξοδα δανείου",
    "",
    "Οχι",
    "1. Πρωτοβάθμια",
    "2. Δευτεροβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
  ],
  [
    "Οικονομικά και Διοίκηση",
    "Μετά από παρότρυνση άλλων ατόμων (οικογένειας, φίλων, καθηγητών...)/Διαφήμιση",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Δεν γνωρίζω/ Δεν απαντώ",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα δανείου",
    "Έξοδα ενοικίου",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "3. Τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Ηνωμένο Βασίλειο/Άλλη χώρα",
    "ενταχθήκατε σε κάποια θέση εργασίας για βιοποριστικούς λόγους",
    "",
    "",
    "Ναι",
    "PhD",
    "Οχι",
    "Οχι",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Λιγότερο από 3 μήνες",
    "Καμία",
    "",
    "Πάνω από 3 φορές",
    "Δεν με ικανοποιούσε η οικονομική πτυχή της εργασίας μου",
    "Η εργασία ή τα καθήκοντα μου δεν ήταν σχετικά με το αντικείμενο σπουδών μου",
    "",
    "Είμαι άνεργος/η",
    "Άλλος λόγος",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "26-30",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "2. Προσοντούχοι και άλλοι ειδικοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Όχι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "Δικτυο γνωριμιών (οικογενειακό, φιλικό, επαγγελματικό)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άλλο",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Οχι",
    "4. Ανώτερη τριτοβάθμια",
    "4. Ανώτερη τριτοβάθμια",
    "7. Δεν εργάζεται/Δεν εργαζόταν",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εκπαίδευσης",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Ιδιωτικό πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, με απολαβές",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "Οχι",
    "",
    "",
    "Καθόλου, εργαζόμουν ήδη",
    "Μια ή δύο φορές",
    "Μεταξύ 3 και 6 μηνών",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Σπουδές στον κλάδο",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Εφαρμοσμένες Επιστήμες",
    "Eνδιαφέρον/περιέργεια για το αντικείμενο σπουδών",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Master",
    "Οχι",
    "Ναι, σε εργασία με συνάφεια με τις σπουδές μου, χωρίς απολαβές (π.χ. πρακτική άσκηση)",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ατομική πρωτοβουλία (π.χ. ενεργή αναζήτηση εργασίας, συμμετοχή σε προγράμματα/σεμινάρια εξειδίκευσης, εθελοντική εργασία, εξω-πανεπιστημιακή ενασχόληση)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Άνδρας",
    "21-25",
    "Έξοδα ενοικίου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "",
    "4. Ανώτερη τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "2. Προσοντούχοι και άλλοι ειδικοί",
  ],
  [
    "Υγείας και Παραϊατρικά",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Οχι",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Έλλειψη/Πίεση χρόνου",
    "Κακές εργασιακές συνθήκες",
    "Αναγκάστηκα, λόγω τερματισμού σύμβασης",
    "Είμαι άνεργος/η",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Έξοδα δανείου",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Κοινωνικές Επιστήμες",
    "Για να ασκήσω το συγκεκριμένο επάγγελμα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "προχωρήσατε σε άλλο πρόγραμμα εκπαίδευσης/κατάρτισης (μεταπτυχιακό, πτυχίο…)",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Ναι, για σπουδές",
    "",
    "",
    "Δεν αναζήτησα αμέσως εργασία",
    "Καμία",
    "",
    "Καμία",
    "",
    "",
    "",
    "Είμαι σε μη-αμειβόμενη εργασιακή κατάσταση",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Νιώθω ότι δεν έχω επαρκή προσόντα",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "21-25",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "3. Τριτοβάθμια",
    "3. Τριτοβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
  [
    "Θετικές Επιστήμες",
    "Δεν πέτυχα σε άλλες επιλογές που έκανα",
    "Μετά το 2013",
    "Δημόσιο πανεπιστήμιο στην Κύπρο",
    "ξεκινήσατε αναζήτηση εργασίας σχετικής με το πτυχίο σας",
    "",
    "",
    "Ναι",
    "Bachelor",
    "Οχι",
    "Ναι, σε εργασία χωρίς συνάφεια με τις σπουδές μου, με απολαβές",
    "",
    "",
    "Οχι",
    "",
    "",
    "Πάνω από 7 μήνες",
    "Πάνω από 3 φορές",
    "Πάνω από 7 μήνες",
    "Καμία",
    "",
    "",
    "",
    "Είμαι άνεργος/η",
    "Ήθελα να επιστρέψω σε εκπαίδευση",
    "Επιθυμία αλλαγής θέσης εργασίας ή πεδίου",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Γυναίκα",
    "26-30",
    "Κανένα από τα πιο πάνω",
    "",
    "Ναι, στήριξη από γονείς/οικογένεια",
    "2. Δευτεροβάθμια",
    "2. Δευτεροβάθμια",
    "3. Υπηρεσίες, Γραφείς, Τεχνικοί και βοηθοί",
    "4. Ειδικευμένοι τεχνίτες, γεωργοί/κτηνοτρόφοι, χειριστές βιομηχανικών εγκαταστάσεων",
  ],
];

const all_variables = [
  {
    name: "Κλάδος Σπουδών",
    value1: 0,
    value2: -1,
    value3: -1,
  },
  {
    name: "Σημαντικότερος λόγος επιλογής κλάδου",
    value1: 1,
    value2: -1,
    value3: -1,
  },
  {
    name: "Έτος αποφοίτησης",
    value1: 2,
    value2: -1,
    value3: -1,
  },
  {
    name: "Ίδρυμα φοίτησης στο προπτυχιακό",
    value1: 3,
    value2: -1,
    value3: -1,
  },
  {
    name: "Πορεία μετά την απόκτηση πρώτου πτυχίου",
    value1: 4,
    value2: 5,
    value3: 6,
  },
  {
    name: "Αμέσως μετά την απόκτηση του πρώτου πτυχίου σας, γνωρίζατε πώς να προχωρήσετε για να αποκτήσετε μια θέση εργασίας που να σχετίζεται με τις σπουδές σας;",
    value1: 7,
    value2: -1,
    value3: -1,
  },
  {
    name: "Υψηλότερο επίπεδο εκπαίδευσης που έχετε παρακολουθήσει",
    value1: 8,
    value2: -1,
    value3: -1,
  },
  {
    name: "Έχετε παρακολουθήσει/παρακολουθείτε κάποιο επιπρόσθετο πρόγραμμα επαγγελματικής κατάρτισης;",
    value1: 9,
    value2: -1,
    value3: -1,
  },
  {
    name: "Εργασία κατά την διάρκεια των σπουδών",
    value1: 10,
    value2: 11,
    value3: 12,
  },
  {
    name: "Έχετε διαμείνει στο εξωτερικό;",
    value1: 13,
    value2: 14,
    value3: 15,
  },
  {
    name: "Διάστημα αναζήτησης εργασίας μετά την απόκτηση του τελευταίου τίτλου σπουδών",
    value1: 16,
    value2: -1,
    value3: -1,
  },
  {
    name: "Εμπειρία ανεργίας",
    value1: 17,
    value2: -1,
    value3: -1,
  },
  {
    name: "Διάρκεια ανεργίας",
    value1: 18,
    value2: -1,
    value3: -1,
  },
  {
    name: "Αλλαγές εργασίας από τον τελευταίο τίτλο σπουδών",
    value1: 19,
    value2: -1,
    value3: -1,
  },
  {
    name: "Λόγοι για αλλαγή εργασίας",
    value1: 20,
    value2: 21,
    value3: 22,
  },
  {
    name: "Σημερινή εργασιακή κατάσταση",
    value1: 23,
    value2: -1,
    value3: -1,
  },
  {
    name: "Λόγοι μη-κατοχής αμειβόμενης θέσης",
    value1: 24,
    value2: 25,
    value3: -1,
  },
  {
    name: "Τομέας απασχόλησης για την κύρια εργασία σας",
    value1: 26,
    value2: -1,
    value3: -1,
  },
  {
    name: "Τύπος σύμβασης",
    value1: 27,
    value2: 28,
    value3: -1,
  },
  {
    name: "Μηνιαίος Μισθός",
    value1: 29,
    value2: -1,
    value3: -1,
  },
  {
    name: "Κάθετη αντιστοιχία προσόντων-θέσης",
    value1: 30,
    value2: -1,
    value3: -1,
  },
  {
    name: "Οριζόντια αντιστοιχία κλάδου-θέσης",
    value1: 31,
    value2: -1,
    value3: -1,
  },
  {
    name: "Λόγοι οριζόντιας αναντιστοιχίας",
    value1: 32,
    value2: -1,
    value3: -1,
  },
  {
    name: "Προσόντα που θεωρώ σημαντικά για εξεύρεση θέσης εργασίας σχετικής με τον κλάδο μου",
    value1: 33,
    value2: 34,
    value3: -1,
  },
  {
    name: "Σημαντικότερα κριτήρια επιλογής εργασίας",
    value1: 35,
    value2: 36,
    value3: -1,
  },
  {
    name: "Επιθυμία αλλαγής εργασίας",
    value1: 37,
    value2: -1,
    value3: -1,
  },
  {
    name: "Λόγοι για επιθυμία αλλαγής εργασίας",
    value1: 38,
    value2: 39,
    value3: -1,
  },
  {
    name: "Επιπρόσθετες θέσεις εργασίας",
    value1: 40,
    value2: -1,
    value3: -1,
  },
  {
    name: "Εργασιακή κατάσταση εκτός αμειβόμενης εργασίας",
    value1: 41,
    value2: 42,
    value3: 43,
  },
  {
    name: "Φύλο",
    value1: 44,
    value2: -1,
    value3: -1,
  },
  {
    name: "Ηλικακή Ομάδα",
    value1: 45,
    value2: -1,
    value3: -1,
  },
  {
    name: "Επιπρόσθετα έξοδα καθημερινότητας",
    value1: 46,
    value2: 47,
    value3: -1,
  },
  {
    name: "Οικονομική στήριξη",
    value1: 48,
    value2: -1,
    value3: -1,
  },
  {
    name: "Επίπεδο εκπαίδευσης Μητέρας",
    value1: 49,
    value2: -1,
    value3: -1,
  },
  {
    name: "Επίπεδο εκπαίδευσης Πατέρα",
    value1: 50,
    value2: -1,
    value3: -1,
  },
  {
    name: "Επάγγελμα μητέρας",
    value1: 51,
    value2: -1,
    value3: -1,
  },
  {
    name: "Επάγγελμα πατέρα",
    value1: 52,
    value2: -1,
    value3: -1,
  },
];

const all_colors = [
  "rgba(68,114,196,1)",
  "rgba(237,125,49,1)",
  "rgba(165,165,165,1)",
  "rgba(255,192,0,1)",
  "rgba(255,30,30,1)",
  "rgba(91,155,213,1)",
  "rgba(112,173,71,1)",
  "rgba(149,79,114,1)",
  "rgba(0,255,255,1)",
  "rgba(216,0,255,1)",
  "rgba(0,255,0,1)",
];

const useStyles = makeStyles(styles);

export default function ResearchPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [the_final_data_bar, setthe_final_data_bar] = useState(null);
  const [ThePassword, setThePassword] = useState("");
  const [ModalAuthorize, setModalAuthorize] = useState(false);
  const [Authorization, setAuthorization] = useState(false);
  const [DependentSelection, setDependentSelection] = useState("All Data");
  const [IndependentSelection, setIndependentSelection] =
    useState("Choose Something");
  const [CreatedIndependentSelection, setCreatedIndependentSelection] =
    useState("");
  const [Dependent_Dropdown, setDependent_Dropdown] = useState([]);
  const [Independent_Dropdown, setIndependent_Dropdown] = useState([]);
  const language = useSelector((state) => state.language.value);

  const createGraph = (depended_labels, the_dataset, independed_labels) => {
    const final_dataset = [];
    for (let i = 0; i < independed_labels.length; i++) {
      final_dataset.push({
        label: independed_labels[i],
        backgroundColor: all_colors[i],
        borderColor: all_colors[i],
        borderWidth: 1,
        hoverBackgroundColor: all_colors[i],
        hoverBorderColor: all_colors[i],
        data: the_dataset[i],
      });
    }
    const bar = {
      labels: depended_labels,
      datasets: final_dataset,
    };
    setthe_final_data_bar(bar);
    setCreatedIndependentSelection(IndependentSelection);
  };

  useEffect(() => {
    let variable_names = [];
    all_variables.forEach((element) => {
      variable_names.push(element.name);
    });
    setIndependent_Dropdown(variable_names);
    variable_names = [
      all_variables[0].name,
      all_variables[2].name,
      all_variables[3].name,
      all_variables[6].name,
      all_variables[15].name,
      all_variables[20].name,
      all_variables[21].name,
      all_variables[29].name,
      all_variables[30].name,
      all_variables[33].name,
      all_variables[34].name,
      all_variables[35].name,
      all_variables[36].name,
    ];

    setDependent_Dropdown(variable_names);
  }, []);

  const GraphCreation = () => {
    if (IndependentSelection == "Choose Something") {
      alert("Choose an Independed variable!");
      return;
    }
    if (IndependentSelection == DependentSelection) {
      alert("Depended and Independed variables must be different!");
      return;
    }
    let i = 0;
    let j = 0;
    let k = 0;
    let dep_variable_column = -1;
    for (i = 0; i < all_variables.length; i++) {
      if (all_variables[i].name == DependentSelection) {
        dep_variable_column = all_variables[i].value1;
        break;
      }
    }
    let ind_variable_columns = [];
    for (i = 0; i < all_variables.length; i++) {
      if (all_variables[i].name == IndependentSelection) {
        ind_variable_columns[0] = all_variables[i].value1;
        ind_variable_columns[1] = all_variables[i].value2;
        ind_variable_columns[2] = all_variables[i].value3;
        break;
      }
    }

    let table = [];
    let deigma_count = [];

    let tmp1 = [];
    let tmp2 = [];
    for (i = 0; i < all_data.length; i++) {
      if (all_data[i][dep_variable_column] != "")
        tmp1.push(all_data[i][dep_variable_column]);
      for (j = 0; j < 3; j++) {
        if (ind_variable_columns[j] != -1) {
          if (all_data[i][ind_variable_columns[j]] != "")
            tmp2.push(all_data[i][ind_variable_columns[j]]);
        }
      }
    }

    var uniqueString = [...new Set(tmp1)].sort();
    var uniqueString2 = [...new Set(tmp2)].sort();

    const all_sciences = uniqueString;

    const the_dataset_labels = uniqueString2;

    for (let i = 0; i < all_sciences.length; i++) {
      table[i] = [];
      deigma_count[i] = 0;
      for (let j = 0; j < the_dataset_labels.length; j++) {
        table[i][j] = 0;
      }
    }
    for (let i = 0; i < all_data.length; i++) {
      let count_1 = -1;
      let count_2 = [-1, -1, -1];
      for (j = 0; j < all_sciences.length; j++) {
        if (all_data[i][dep_variable_column] == all_sciences[j]) {
          count_1 = j;
          break;
        }
      }
      for (j = 0; j < the_dataset_labels.length; j++) {
        for (k = 0; k < 3; k++) {
          if (ind_variable_columns[k] == -1) {
            continue;
          }
          if (all_data[i][ind_variable_columns[k]] == the_dataset_labels[j]) {
            count_2[k] = j;
          }
        }
      }
      let flag = false;
      if (count_1 != -1) {
        for (k = 0; k < 3; k++) {
          if (count_2[k] != -1) {
            table[count_1][count_2[k]]++;
            flag = true;
          }
        }
        if (flag) {
          deigma_count[count_1]++;
        }
        flag = false;
      }
    }

    let maximum_value_y_axis = 0

    const tmp_chart = [];
    for (let i = 0; i < the_dataset_labels.length; i++) {
      tmp_chart[i] = [];
      for (let j = 0; j < all_sciences.length; j++) {
        tmp_chart[i][j] = Math.round((table[j][i] / deigma_count[j]) * 100);
        if(tmp_chart[i][j] > maximum_value_y_axis ){
          maximum_value_y_axis = tmp_chart[i][j]
        }
      }
    }

    maximum_value_y_axis = Math.ceil(maximum_value_y_axis / 10) * 10 + 10

    if(maximum_value_y_axis > 100){
      maximum_value_y_axis = 100
    }

    options.scales.yAxes[0].ticks.max = maximum_value_y_axis

    if (all_sciences.length == 1) {
      createGraph([""], tmp_chart, the_dataset_labels);
    } else {
      createGraph(all_sciences, tmp_chart, the_dataset_labels);
    }
  };

  const SearchPolitics = () => {
    alert("hello");
  };

  return (
    <div>
      <Header
        color="white"
        // routes={dashboardRoutes}
        brand={
          <Logo_tmp
            onClick={() => {
              window.location.href = "/";
            }}
          />
        }
        rightLinks={<HeaderLinks />}
        fixed
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white",
        // }}
        {...rest}
      />
      <div
        style={{
          height: 200,
        }}
      ></div>
      <div
        className={classNames(classes.main, classes.mainRaised)}
        // style={{
        //   paddingBottom: "100px",
        // }}
      >
        <div className={classes.sections}>
          <div className={classes.container}>
            <GridContainer justify="center" style={{marginBottom: "30px"}}>
              <GridItem xs={12} sm={12} md={12}>
                <h2
                  style={{
                    color: "#3C4858",
                    margin: "1.75rem 0 0.875rem",
                    textDecoration: "none",
                    fontWeight: "700",
                    marginTop: "50px",
                    minHeight: "32px",
                    marginBottom: "1.5em",
                  }}
                >
                  {language
                    ? "Διαδραστική Βάση Δεδομένων"
                    : "Interactive data base"}
                </h2>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={9}
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <h4>
                  <b>
                    {language
                      ? "Έρευνα για τη σχέση των νέων πτυχιούχων με την εργασία (2021)"
                      : "Research on the relationship with work of young graduates (2021)"}
                  </b>
                </h4>
                <p>
                  {language
                    ? "Τα αποτελέσματα του προγράμματος YouthTrams παρουσιάζονται μέσα από μια διαδραστική στατιστική βάση δεδομένων που επιτρέπει στον καθένα και στην καθεμιά να επιλέξει τα δεδομένα που τον/την ενδιαφέρουν. Επιλέγοντας την ερώτηση που επιθυμείτε από τον κατάλογο, τα δεδομένα της έρευνας για την ερώτηση αυτή θα εμφανιστούν σε γραφική παράσταση."
                    : "The results of the YouthTrams project are presented through an interactive statistical database. By selecting the desired question from the list below, the survey data for that question will be displayed in the form of a graph."}
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img
                  src={researchPageIcon}
                  alt="..."
                  style={{ margin: "0px", padding: "0px",width: "100%" }}
                  // className={classes.imgFluid + " " + classes.title}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    "text-align": "right",
                  }}
                >
                  <Button
                    round
                    style={{
                      "font-family": "Courier New",
                      border: "1px solid",
                      backgroundColor: Authorization ? "white" : "green",
                      "border-color": Authorization ? "green" : "white",
                      color: Authorization ? "green" : "white",
                      fontWeight: "700",
                    }}
                    onClick={() => {
                      setModalAuthorize(!ModalAuthorize);
                    }}
                  >
                    {language ? "Περιορισμενη Προσβαση" : "Authorize"}
                    &nbsp;&nbsp;
                    {Authorization ? <LockOpenIcon /> : <LockIcon />}
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  "padding-left": "0px",
                  "padding-right": "0px",
                  // "margin-right": "15px",
                  "background-color": "#fbc324",
                }}
              >
                <span
                  style={{
                    color: "black",
                    "margin-top": "0px",
                    "margin-bottom": "0px",
                    "margin-left": "0px",
                    "font-size": "1.5rem",
                  }}
                >
                  &nbsp;
                  {language
                    ? "Γραφικές Αναπαραστάσεις δεδομένων"
                    : "Graphic Data Representations"}
                </span>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  "background-color": "#f3f0e9",
                }}
              >
                <Box margin={1}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{
                      padding: "0px",
                    }}
                  >
                    <CFormGroup>
                      <CLabel htmlFor="Dependent">
                        {language
                          ? "Συσχέτιση μεταβλητών (μόνο για περιορισμένη πρόσβαση)"
                          : "Variables correlation(for limited access only)"}
                      </CLabel>
                      <Autocomplete
                        id="Dependent"
                        size="small"
                        style={{
                          "margin-top": "1rem",
                          "margin-bottom": "1rem",
                          "font-family": "Courier New",
                        }}
                        autoComplete="off"
                        options={Dependent_Dropdown}
                        value={DependentSelection}
                        disabled={Authorization ? false : true}
                        onChange={(event, value) => {
                          if (String(value) == "null") {
                            setDependentSelection("All Data");
                          } else {
                            setDependentSelection(String(value));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={language ? "Επιλέξτε" : "Choose"}
                            variant="outlined"
                          />
                        )}
                      />
                    </CFormGroup>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      padding: "0px",
                    }}
                  >
                    <CFormGroup>
                      <CLabel htmlFor="Independent">
                        {language ? "Επιλέξτε ερώτηση" : "Choose a question"}
                      </CLabel>
                      <Autocomplete
                        id="Independent"
                        size="small"
                        style={{
                          "margin-top": "1rem",
                          "margin-bottom": "1rem",
                          "font-family": "Courier New",
                        }}
                        options={Independent_Dropdown}
                        value={IndependentSelection}
                        onChange={(event, value) => {
                          if (String(value) == "null") {
                            setIndependentSelection("Choose Something");
                          } else {
                            setIndependentSelection(String(value));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={language ? "Επιλέξτε" : "Choose"}
                            variant="outlined"
                          />
                        )}
                      />
                    </CFormGroup>
                  </GridItem>
                  <div
                    style={{
                      "text-align": "right",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "blue",
                        "font-family": "Courier New",
                        fontWeight: "700",
                      }}
                      onClick={() => {
                        GraphCreation();
                      }}
                    >
                      {language ? "Δημιουργια" : "Create"}
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "blue",
                        "font-family": "Courier New",
                        fontWeight: "700",
                      }}
                      onClick={() => {
                        setthe_final_data_bar(null);
                        setDependentSelection("All Data");
                        setIndependentSelection("Choose Something");
                      }}
                    >
                      {language ? "Εκκαθαριση" : "Clear"}
                    </Button>
                  </div>
                </Box>
                {the_final_data_bar != null ? (
                  <div>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <h4 style={{ "text-align": "center" }}>
                          <b>{CreatedIndependentSelection}</b>
                        </h4>
                        <div className="chart-wrapper">
                          <CChart
                            style={{ height: "400px" }}
                            type="bar"
                            datasets={the_final_data_bar.datasets}
                            options={options}
                            labels={the_final_data_bar.labels}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                ) : null}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <br></br>
                <b>
                  <p>
                    {language
                      ? "Πηγή: YouthTrams, 2021"
                      : "Source: YouthTrams, 2021"}
                  </p>
                </b>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Dialog
        open={ModalAuthorize}
        onClose={() => setModalAuthorize(!ModalAuthorize)}
      >
        <DialogTitle id="form-dialog-title">Authorization</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              "font-family": "Courier New",
            }}
          >
            Please enter the authorization code to be verified:
          </DialogContentText>
          <TextField
            margin="dense"
            id="Auth Password"
            label="Password"
            type="password"
            autoComplete="off"
            style={{ width: "100%" }}
            variant="outlined"
            value={ThePassword}
            onChange={(e) => {
              setThePassword(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              "font-family": "Courier New",
              border: "1px solid",
              backgroundColor: "white",
              "border-color": "green",
              color: "green",
            }}
            onClick={() => {
              setModalAuthorize(!ModalAuthorize);
              if (ThePassword === "youthtrams2021UCY") {
                setThePassword("");
                setAuthorization(true);
                alert("Authorization Granted!!!");
              } else {
                setThePassword("");
                setAuthorization(false);
                alert("Authorization Denied!!!");
              }
            }}
          >
            Verify
          </Button>
          <Button
            style={{
              "font-family": "Courier New",
              border: "1px solid",
              backgroundColor: "white",
              "border-color": "red",
              color: "red",
            }}
            onClick={() => {
              setModalAuthorize(!ModalAuthorize);
              setThePassword("");
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </div>
  );
}
