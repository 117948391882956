import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import { useSelector } from 'react-redux'
import { List, ListItem } from "@material-ui/core";

import original from "assets/Original.png";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const language = useSelector((state) => state.language.value)
  const classes = useStyles();

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");


  async function sendEmail() {
    const the_to_email = "youthtrams.ucy@gmail";
    // const the_to_email = "chris@thetensortech.com";
    // const the_subject = "Contact Us form Email";
    const the_telephone = ''

    const the_name = Name;
    const the_from_email = Email;
    const the_message = Message;

    let response = "";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        toemail: the_to_email,
        name: the_name,
        fromemail: the_from_email,
        telephone: the_telephone,
        message: the_message,
      }),
    };

    try {
      response = await fetch(
        "https://email-service.thetensortech.com/api/send-email/",
        requestOptions
      );
    } catch (error) {
      console.log(error);
      alert("Error in sending information");
      return;
    }
    if (response.status !== 200) {
      console.log(response);
      alert("Invalid Input");
      return;
    }
    alert("Email has been sent!");
    clearValues();
  }

  function clearValues() {
    setName("");
    setEmail("");
    setMessage("");
  }

  return (
    <div className={classes.sections}>
      <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={12}>
          <h2 className={classes.major_title}>
            {language ? "Επικοινωνήστε μαζί μας" : "Contact us"}
          </h2>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                    <p
                      // style={{
                      //   textAlign: "left",
                      // }}
                    >
                      {language
                        ? "Για περισσότερες πληροφορίες σχετικά με το έργο YouthTrams, τα ερευνητικά αποτελέσματα, δυνατότητα συμμετοχής σε έρευνα ή δυνατότητα ερευνητικής συνεργασίας, μπορείτε να επικοινωνήσετε με τη Συντονίστρια Δρ. Σοφία Σταύρου."
                        : "For more information about the YouthTrams project, the research results, the possibility of participating in research or the possibility of research collaboration, you can contact the coordinator Dr. Sophia Stavrou."}
                    </p>
                    <br></br>
                    <p>
                      <b>

                    Email: sofias@ucy.ac.cy
                      </b>
                    </p>
                    <p>
                      <b>
                    Facebook:&nbsp;

                      </b>
                    
                    <a
                      href="https://www.facebook.com/YouthTrams-108938120550963/"
                      target="_blank"
                      style={{
                        color: "blue",
                      }}
                    >
                      YouthTrams
                    </a>

                    </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.title} style={{ marginTop: "100px", textAlign: "left" }}>
                  {language
                    ? "Μπορείτε να υποβάλετε την ερώτηση ή το σχόλιό σας πιο κάτω:"
                    : "You can submit your question or comment below:"}
                </h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={Name}
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={Email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </GridItem>
              <br></br>
              <br></br>
              <br></br>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  multiline
                  margin="dense"
                  id="message"
                  label="Message"
                  type="text"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={Message}
                  required
                  rows={10}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Button
                  round
                  style={{
                    "font-family": "Courier New",
                    border: "1px solid",
                    backgroundColor: "white",
                    "border-color": "blue",
                    color: "blue",
                  }}
                  onClick={() => {
                    if (
                      Name === "" ||
                      Email === "" ||
                      Message === "" 
                      ){
                        alert("Συμπληρώστε όλα τα πεδία με αστεράκι για να είναι έγκυρη η φόρμα επικοινωνίας!")
                      }
                      else{
                        sendEmail();
                      }
                  }}
                >
                  {language ? "Αποστολη" : "Send"}
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} align="center">
              <br></br>
              <img src={original} style={{ width: "50%" }} />
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      </div>
    </div>
  );
}
