import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useSelector } from 'react-redux'

import methodology from "assets/methodology.png";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

import original from "assets/Original.png";
import koinwnikes_english from "assets/ucy_koinwnikes_english.png";
import koinwnikes_greek from "assets/ucy_koinwnikes_greek.png";
import ucy from "assets/ucy.png";

const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const language = useSelector((state) => state.language.value)
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div id="intro">
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} style={{marginBottom: "30px"}}>
              <br></br>
              <img src={original} style={{ width: "100%" }} />
              <div
                style={{
                  height: 50,
                }}
              ></div>
              <h2 className={classes.major_title} >YouthTrams Observatory</h2>
              <p
              >
                {language
                  ? "Το έργο YouthTrams στοχεύει στην προώθηση της ακαδημαϊκής έρευνας στην Κύπρο για τη νεολαία και τη σχέση μεταξύ ανώτατης εκπαίδευσης και εργασίας."
                  : "The YouthTrams project aims to promote academic research in Cyprus on youth and the relationship between higher education and work."}
              </p>
              <br></br>
              <p
              >
                {language
                  ? "Το έργο YouthTrams ξεκίνησε στο πλαίσιο της εναρκτήριας χρηματοδότησης της Δρ. Σοφίας Σταύρου (2019-2021), με στόχο τη δημιουργία Παρατηρητηρίου για τη σχέση των νέων με την ανώτατη εκπαίδευση και την εργασία στην Κύπρο. Η διαδραστική βάση δεδομένων τροφοδοτείται από την ακαδημαϊκή έρευνα που διεξάγεται στο Τμήμα Κοινωνικών και Πολιτικών Επιστημών του Πανεπιστημίου Κύπρου με σκοπό τη διάχυση των αποτελεσμάτων της έρευνας και την αξιοποίηση τους στη διδασκαλία μαθημάτων γύρω από ζητήματα νεολαίας, των σχέσεων εκπαίδευσης και εργασίας στη σύγχρονη κοινωνία, καθώς και ζητήματα κοινωνικής στρωμάτωσης, κοινωνικής κινητικότητας και ανισότητας."
                  : "The YouthTrams project was launched under the Start-Up funding scheme of Dr. Sophia Stavrou (2019-2021), aiming to establish an Observatory on the relationship between youth and education and work in Cyprus. The objective is the dissemination to society of the research results provided by academic research conducted at the University of Cyprus (Department of Social and Political Sciences), as well their use in university teaching in courses on youth, education and work in contemporary society, issues of social stratification, social mobility and inequality. "}
              </p>
              <br></br>
              <p
              >
                {language
                  ? "Το έργο χρηματοδοτείται από το Πανεπιστήμιο Κύπρου και υποστηρίζεται από το Τμήμα Κοινωνικών και Πολιτικών Επιστημών."
                  : "The project is funded by the University of Cyprus and supported by the Department of Social and Political Sciences."}
              </p>
            </GridItem >
            <GridItem  xs={12} sm={5} md={3}>
            <a href="https://newdev.ucy.ac.cy/sap">
              {language
                ? <img src={koinwnikes_greek} style={{height: '100px', width: '100%'}}/>
                : <img src={koinwnikes_english} style={{height: '100px', width: '100%'}}/>
              } 
              </a>
            </GridItem>
            <GridItem  xs={12} sm={5} md={3}>
              <a href="https://www.ucy.ac.cy">
                <img src={ucy} style={{height: '80px', width: '100%'}}/>
              </a>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
