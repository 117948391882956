import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useSelector } from 'react-redux'

// import methodology from "assets/methodology.png";
import ergo from "assets/ergo.png";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const language = useSelector((state) => state.language.value)
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "0px" }}>
            <h2 className={classes.title} style={{ marginBottom: "0px" }}>
              YouthTrams 2019-2021
            </h2>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <h3 style={{ marginTop: "0px", marginBottom: "1.5em", }}>
              From Higher Education to Work: Sociology of Youth Trajectories in
              the Making
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h2 className={classes.major_title}>
              {language ? "Στόχος" : "Objectives"}
            </h2>
            <p>
              {language
                ? "Το ερευνητικό πρόγραμμα YouthTrams 2019-2021 μελετά τις πορείες μετάβασης των νέων από την ανώτατη εκπαίδευση στην εργασία στην Κύπρο, στο πλαίσιο των οικονομικών, κοινωνικών και πολιτισμικών αλλαγών που χαρακτηρίζουν τις σύγχρονες κοινωνίες, ιδιαίτερα σε μια περίοδο οικονομικής κρίσης, αυξημένης ανεργίας, υπο-απασχόλησης και επισφαλειοποίησης της εργασίας των νέων ενώ η επένδυση στην ανώτατη εκπαίδευση για την ανάπτυξη μιας κοινωνίας-οικονομίας της γνώσης παραμένει κεντρικός πολιτικός στόχος."
                : "The research project YouthTrams 2019-2021 investigates the transitions of young people from higher education to work in Cyprus, in the context of the economic, social and cultural changes that characterise contemporary societies, especially in a period of economic crisis, increased unemployment, under-employment and precariousness of youth work, while at the same time investment in higher education for the development of a knowledge-based society remains a central political objective."}
            </p>
            <br></br>
            <p>
              {language
                ? "Η έρευνα επικεντρώνεται στην κοινωνιολογική διάσταση του προβλήματος της απασχολησιμότητας των πτυχιούχων, με στόχο την κατανόηση των κοινωνικών διαδικασιών που συμβάλλουν στη διαμόρφωση της πορείας μετάβασης των νέων από την ανώτατη εκπαίδευση στην εργασία, πέραν από την «αναντιστοιχία» (mismatch) μεταξύ προσφοράς και ζήτησης προσόντων. Διερευνώνται οι θεσμικοί και ατομικοί παράγοντες, οι κοινωνικές εμπειρίες και σχέσεις που επιδρούν στην κάθε πορεία. Δίνεται ιδιαίτερη έμφαση στην ανάδειξη των μετασχηματισμών που πραγματοποιούνται τα τελευταία χρόνια στις νεανικές πορείες μετάβασης λόγω της οικονομικής κρίσης και άλλων πολιτισμικών αλλαγών (επέκταση της περιόδου ενηλικίωσης, απο-γραμμοποίηση της μετάβασης στην εργασία, αποστροφή από κλασικές μορφές εργασίας, μεγάλη γεωγραφική κινητικότητα, αναζήτηση δημιουργικής εργασίας κοκ.)."
                : "The research focuses on the sociological dimension of the problem of graduate employability, with the aim of understanding the social processes that contribute to shaping the transitions of young people from higher education to work, beyond the mismatch between supply and demand of qualifications. Institutional and individual factors, social experiences and relationships that influence each pathway are explored. Particular emphasis is placed on the recent transformations in young people's transitions as a result of the economic crisis and other cultural changes (extension of the period of adulthood, de-regulation of the transition to work, rejection of traditional forms of work, increased geographical mobility, the search for creativity, strategies to economic insecurity, etc.)."}
            </p>
            <br></br>
            <p>
              {language
                ? "Το έργο αποσκοπεί στην προώθηση της κοινωνιολογικής έρευνας γύρω από τους νέους και τη σχέση μεταξύ ανώτατης εκπαίδευσης και εργασίας, ώστε να συμβάλει στη συζήτηση γύρω από τα προβλήματα ανεργίας, διαγενεακής ανισότητας, και κοινωνικού αποκλεισμού των νέων και τη διαμόρφωση κοινωνικής πολιτικής για την αντιμετώπισή τους."
                : "The project aims to promote sociological research on youth and the relationship between higher education and work, in order to contribute to the debate on the problems of unemployment, intergenerational inequality and social exclusion of young people and the conception of social policies to address them."}
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img
              src={ergo}
              alt="..."
              className={
                classes.imgRoundedCircle +
                " " +
                classes.imgFluid +
                " " +
                classes.title
              }
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
