import {
  container,
  cardTitle,
  title,
  defaultFont,
} from "assets/jss/material-kit-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

const basicsStyle = {
  sections: {
    padding: "20px 0",
  },
  container: {
    ...container,
    marginBottom: "70px"
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    lineHeight: "1.3em"
  },
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
    lineHeight: "1.3em"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    color: "#",
    lineHeight: "1.3em"
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  space50: {
    height: "50px",
    display: "block",
  },
  space70: {
    height: "70px",
    display: "block",
  },
  icons: {
    width: "17px",
    height: "17px",
    color: "#FFFFFF",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  margin5: {
    margin: "5px",
  },
  major_title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    lineHeight: "1.3em",
    marginBottom: "1.5em",
  },
  defaultFont: {
    ...defaultFont,
  },
  ...customCheckboxRadioSwitch,
  ...imagesStyles,
};

export default basicsStyle;
