import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useSelector } from 'react-redux'

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

import aim from "assets/aim.png";
const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const language = useSelector((state) => state.language.value)
  const classes = useStyles();

  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.major_title}>
                {language ? "Μεθοδολογία" : "Methodology"}
              </h2>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <p>
              {language
                ? "Το ερευνητικό πρόγραμμα YouthTrams βασίζεται σε μεικτές μεθόδους έρευνας των κοινωνικών επιστημών. Συνδυάζει ποιοτική έρευνα, αρχειακή, έρευνα μέσω συνεντεύξεων, καθώς και ποσοτικές μεθόδους."
                : "The YouthTrams research project is based on mixed methods of social sciences. It combines qualitative research, archival research, in-depth interview research, and quantitative methods, including research through questionnaire and secondary statistical data. "}
            </p>
            <p>
              {language
                ? "Οι κύριες φάσεις του έργου περιλαμβάνουν:"
                : "The main phases of the project include:"}
            </p>
            <br></br>
            <p>
              <b>
                {language
                  ? "Ποσοτική έρευνα για τη Σχέση των Νέων Πτυχιούχων με την Εργασία:"
                  : "A) Quantitative Research on Young graduates' Trajectories and Relationship with Work:"}
              </b>
              <br></br>
              {language
                ? "Παραγωγή ποσοτικών δεδομένων μέσα από έρευνα μέσω ερωτηματολογίου (διαδικτυακή) μεγάλης εμβέλειας αποφοίτων πανεπιστημίων του εσωτερικού και του εξωτερικού από το 2007 μέχρι το 2020, η οποία λαμβάνει υπόψη την ετερογένεια του πληθυσμού ως προς τα κοινωνικά του χαρακτηριστικά και την ένταξη του στην αγορά εργασίας."
                : "Production of quantitative data through a large-scale (online) questionnaire survey of university graduates from 2007 to 2020, which takes into account the heterogeneity of the population in terms of social characteristics and of forms of transition to work"}
            </p>
            <br></br>
            <p>
              <b>
                {language
                  ? "Ποιοτική έρευνα για τις Πορείες Μετάβασης των Νέων από την Ανώτατη Εκπαίδευση στην Εργασία:"
                  : "B) Qualitative Research on the Transitions of Young People from Higher Education to Work:"}
              </b>

              <br></br>
              {language
                ? "Σε βάθος ανάλυση της διαμόρφωσης της ατομικής πορείας μετάβασης των νέων από το πανεπιστήμιο στην εργασία, μέσα από ποιοτική έρευνα με ημι-κατευθυνόμενες συνεντεύξεις, με διαφορετικές υπο-ομάδες νέων ανάλογα με την εργασιακή τους κατάσταση: νέοι σε αντιστοιχισμένες θέσεις απασχόλησης, νέοι σε ετεροαπασχόληση, νέοι εκτός απασχόλησης, εκπαίδευσης ή κατάρτισης (NEETs)."
                : "Investigation of the configuration of young people's individual transition pathways from university to work, through qualitative research with in-depth comprehensive interviews, with different sub-groups of young people according to their employment status: young people in matched jobs, young people in mismatched jobs, young people not in employment, education or training (NEETs)."}
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <img
              src={aim}
              alt="..."
              style={{margin: "0px", padding: "0px"}}
              className={
                classes.imgFluid +
                " " +
                classes.title
              }
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
